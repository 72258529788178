import "./index.less";
import { Button, message } from "antd";
import video from "../../../../images/video.png";
import { statisticsUpload } from "../../../../api/api";

function Card(props) {
  const course = props.course;
  const jumpCourse = (url) => {
    if (url) {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        // 获取单个查询参数的值
        const key = urlParams.get("key") || "unknown";
        console.log("ylog:14-2e4ab9-key", key);
        statisticsUpload({
          key,
          type: "buy",
          time: new Date().getTime(),
          extra: {
            title: course.column_title
          }
        });
      } catch {
        // noop
      }
      window.open(url, "_blank");
    } else {
      message.info(
        "请等待更新购买链接，感谢支持。也可以在上边的优惠规则中添加微信询问链接"
      );
    }
  };
  return (
    <div
      className="card"
      onClick={() => {
        jumpCourse(course.share_url);
      }}
    >
      <div className="img">
        <img
          alt="img"
          referrerPolicy="no-referrer"
          className="background"
          src={course.column_cover_small}
        />
        {course.column_type === 3 && (
          <img className="video" alt="img" src={video} />
        )}
      </div>
      <div className="content">
        <div className="info">
          <div className="title">{course.column_title}</div>
          <div className="author">{course.column_subtitle}</div>
          <div className="people">{course.sub_count} 人已学习</div>
        </div>
        <div className="action">
          <div className="action-left">
            {course.price_type === 2 && (
              <div className="price">
                <div className="promotion">
                  限时 : ¥{course.column_price / 100}
                </div>
                <div className="origin">
                  原价 : ¥{course.column_price_market / 100}
                </div>
              </div>
            )}
            {course.price_type === 3 && (
              <div className="price">
                <div className="promotion">
                  拼团 : ¥{course.column_price / 100}
                </div>
                <div className="origin">
                  原价 : ¥{course.column_price_market / 100}
                </div>
              </div>
            )}
            {course.price_type === 1 && (
              <div className="price">
                <div className="promotion">
                  ¥{course.column_price_market / 100}
                </div>
              </div>
            )}

            <div className="return">
              成功购买后可得红包¥{course.channel_back_amount / 100}
            </div>
          </div>
          <div className="aciton-right">
            {course.share_url ? (
              <Button className="button" shape="round" type="primary">
                立即购买
              </Button>
            ) : (
              <Button className="button" shape="round" type="primary">
                等待更新
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
