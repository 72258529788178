
import React from 'react';
import './index.css'
import { BackTop as Back } from 'antd';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
function BackTop() {
    return (
        <Back>
            <div className="icon">
                <VerticalAlignTopOutlined />
            </div>
        </Back>
    );
}

export default BackTop;