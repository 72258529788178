
import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import Card from '../card/index'
import './index.less'
import ScrollView from "react-fast-scroll";
import { queryCourse, searchCourse } from '../../../../api/api'

const list = ["最新", "最热", "活动"]
let allCourses = null; //前端伪分页
let offset = 0;
const limit = 30;
function Course({ column_type, platform_type, searchKey }) {
    const [courseList, setCourseList] = useState([]);
    const [focusIndex, setFocusIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const refreshData = (index, type) => {
        return new Promise(async resolve => {
            setIsLoading(true)
            const res = await queryCourse({ sort_type: index + 1, column_type: type, platform_type });
            setIsLoading(false)
            allCourses = res.data;
            setCourseList(allCourses.slice(0, limit))
            offset = limit;
            if (offset >= allCourses.length) {
                resolve(true)
            } else {
                resolve(false)
            }
        });
    }

    const searchData = (index, type, column_title) => {
        return new Promise(async resolve => {
            setIsLoading(true)
            const res = await searchCourse({ sort_type: index + 1, column_type: type, column_title });
            setIsLoading(false)
            allCourses = res.data;
            setCourseList(allCourses.slice(0, limit))
            offset = limit;
            if (offset >= allCourses.length) {
                resolve(true)
            } else {
                resolve(false)
            }
        });
    }

    useEffect(() => {
        // useEffect 的 callback 要避免直接的 async 函数，需要封装一下 
        const doAsync = async (index, type, searchKey) => {
            if (searchKey) {
                await searchData(index, type, searchKey)
                return;
            }
            await refreshData(index, type);
        };
        doAsync(focusIndex, column_type, searchKey); 
    }, [column_type, focusIndex, searchKey, platform_type]);


    const handleClick = async (index) => {
        setFocusIndex(index)

    }

    const pullDown = () => {
        if (searchKey) {
            return searchData(focusIndex, column_type, searchKey)
        }
        return refreshData(focusIndex, column_type,platform_type)
    };

    const pullUp = () => {
        return new Promise(async resolve => {
            if (!allCourses) {
                const res = await queryCourse({ sort_type: focusIndex + 1 });
                allCourses = res.data;
                setCourseList(allCourses.slice(0, limit))
            }
            setCourseList(allCourses.slice(0, offset + limit))
            offset = offset + limit;
            if (offset >= allCourses.length) {
                resolve(true)
            } else {
                resolve(false)
            }
        })
    }
    return (
        <div>
            <div className="header">
                {
                    list.map((item, index) => <div className={index === focusIndex ? "item item-focus" : "item"} onClick={() => handleClick(index)} key={index}>
                        {item}
                    </div>)
                }

            </div>
            <ScrollView
                pullUp={pullUp}
                pullDown={pullDown}
                isUseBodyScroll={true}
                up={{ isAutoLoad: false }}
            >
                <Spin spinning={isLoading}>
                    <div className="courses">
                        {courseList.map(item => <Card className="card" key={item.id} course={item}></Card>)}
                    </div>
                </Spin>

            </ScrollView>

        </div>
    );
}

export default Course;