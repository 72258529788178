import { get, post } from './server'

export const queryCourse = function(params) {
    return get('/api/query', params)
}

export const searchCourse = function(params) {
    return get('/api/search', params)
}

export const statisticsUpload = function(body) {
    return post('/api/statistics', body)
}