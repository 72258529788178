import fetch from "node-fetch";
const DOMAIN_MAP = {
    'development': 'http://localhost:3002',
    'production': 'https://coursesub.top',
}
const ENV = process.env.NODE_ENV;
export const get = function(url, params) {
    const domain = new URL(`${DOMAIN_MAP[ENV]}${url}`)
    domain.search = new URLSearchParams(params).toString();
    return fetch(domain).then(res => res.json())
}

export const post = function(url, body) {
    const domain = new URL(`${DOMAIN_MAP[ENV]}${url}`)
    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      };
      
    return fetch(domain, options).then(res => res.json())
}