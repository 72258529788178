
import React from 'react';
import './index.css'

function Header() {
    return (
        <div className="footer">
            <div>Copyright © {new Date().getFullYear()} by: 公众号-课程减减</div>
            <div>友情链接：<a href='https://xiaobaot.top/' target="_blank">精选小报童专栏导航站</a> 
                &#20;&#20;<a href='https://wildcard.com.cn/i/LIANG89' target="_blank">chatGPT 及 API 随心用</a>
            </div>
        </div>
    );
}

export default Header;