import React, { useState } from "react";
import { Input, Menu } from "antd";
import Course from "./components/course";
import "./index.css";

const { Search } = Input;

function Body() {
    const [type, setType] = useState("0");
    const [searchKey, setSearchKey] = useState("");
    const onSearch = (key) => {
        setSearchKey(key);
    };
    const handleClick = (e) => {
        setType(e.key);
    };
    return (
        <div className="body">
            <Search
                placeholder="请输入课程名称查询"
                allowClear
                onSearch={onSearch}
                style={{ width: 300 }}
            />
            {!searchKey && (
                <Menu
                    onClick={handleClick}
                    selectedKeys={[type]}
                    mode="horizontal"
                >
                    <Menu.Item key={0}>极客时间</Menu.Item>
                    <Menu.Item key={1}>稀土掘金</Menu.Item>
                </Menu>
            )}
            <Course
                column_type={0}
                platform_type={type}
                searchKey={searchKey}
            ></Course>
        </div>
    );
}

export default Body;
